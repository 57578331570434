





















import { defineComponent, onMounted, ref } from "@vue/composition-api";
import ElTablePlus, { TableActionClickCallbackContext, TableApis, TableColumn, TableRow, TableSearchOptionItem } from "@/components/el-table-plus.vue";
import { FormStructureItem } from "@/components/el-form-plus/index.vue";
import { $post } from "@/http/axios";
import ElFormPlus from "../../components/el-form-plus/index.vue";
import Dictionary, { DICTIONARY_NAME } from "@/common/dictionary"
import { LEASE_STATUS_DICT, PUBLISH_STATUS_DICT } from "./common";
type DICT = {[key:number]:string}
export default defineComponent({
    name: "SpaceResource",
    components: { ElTablePlus, ElFormPlus },
    setup(props,ctx){
        //@ts-ignore
        onMounted(()=>window.that = ctx.root)
        /** 租赁状态字典 */
        //  const  LEASE_STATUS_DICT:DICT = {0:"-",1:"待租赁",2:"租赁中",3:"已租赁"}
        // const  PUBLISH_STATUS_DICT:DICT = {0:"草稿",1:"待审批",2:"审批不通过",3:"待租赁"}
        const tableRef = ref();
         const handleSubmitApply = async () =>{
             if(Object.entries(applyForm.value).map(item=>item[1]).some(item=>!item)){
                ctx.root.$message.error('请完整填写表单');
                 return;
             }
             console.log(`外部提交`,applyForm.value,applyContext.value)
            let {success,message} = await $post('/enterprisespaceresources/enterpriseSpaceResourcesApply',{
                ...applyForm.value,
                address:" ",
                resourcesId:applyContext.value!.id
            })
            if(success){
                ctx.root.$message.success(message);
                showApplyFormDialog.value = false
                applyContext.value!.context.refresh()
            }else{
                ctx.root.$message.error(message);
            }

        }
        const apis:TableApis = {
            list:'/enterprisespaceresources/findOtherEnterpriseSpaceResourcesList',
            save:'/enterprisespaceresources/editEnterpriseSpaceResources',
            detail:'/enterprisespaceresources/getEnterpriseSpaceResourcesById',
            remove:'/enterprisespaceresources/deleteEnterpriseSpaceResourcesById',
        }
        const columns:TableColumn[] = [
            { label: "厂房地址",prop: "address",width:250},
            { label: "厂房面积(㎡)",prop: "idleArea"},
            { label: "发布企业",prop: "enterpriseName"},
            { label: "企业法人",prop: "legalPerson"},
            { label: "法人电话",prop: "legalPersonPhone"},
            { label: "要求达产目标值",prop: "targetValue"},
            { label: "要求承诺年税收",prop: "annualTax"},
            { label: "资源状态",prop: "publishStatus",formatter:PUBLISH_STATUS_DICT },
            { label: "操作",type:'actions',fixed:'right',width:180,buttons:[
                {label: "申请",type:'primary',click:(row,context)=>{
                    applyContext.value= {id:row.id,context:context}
                    showApplyFormDialog.value = true
                }},
                {label: "详情",type:'primary',action:'DETAIL'},
            ]},
        ]
        /**
         * 表单结构
         */
        const formStructure:FormStructureItem[] = [
              { label:"发布企业",key:'enterpriseName',type:'text',span:8},
            { label:"企业法人",key:'legalPerson',type:'text',span:8},
            { label:"法人电话",key:'legalPersonPhone',type:'text',span:8},   
            { label:"要求达产目标值",key:'targetValue',type:'text'},
            { label:"要求承诺年税收",key:'annualTax',type:'text'},
            { label:"资源状态",key:'publishStatus',type:'select',options:PUBLISH_STATUS_DICT},
            { label:"租赁状态",key:'leaseStatus',type:'select',options:LEASE_STATUS_DICT},
         
            { label:"厂房地址",key:'address',type:'textarea',span:24},
        ]
        const searchOptions:TableSearchOptionItem[]=[
            { label:'资源地址',type:'text',key:'landName'},
            { label:'联系人',type:'text',key:'entContact'},
            { label:'联系方式',type:'text',key:'contactWay'},
        ]
       
       /**
        * 申请表单相关逻辑
        */
       const applyFormStructure:FormStructureItem[]= [
        //    {label:'企业名称',key:'enterpriseName',type:'text',required:true,span:8},
          
           {label:'企业法定代表人',key:'legalPerson',type:'text',required:false},
           {label:'法人联系电话',key:'legalPersonPhone',type:'text',required:false},
            {label:'行业类别',key:'industryCategory',type:'text' , required:false,span:8},
            {label:'需求面积(㎡)',key:'demandArea',type:'text' , required:false,span:8},
           {label:'主营业务',key:'mainBusiness',type:'text',required:false,span:8},
           {label:'承诺资产投入',key:'assetInvestment',append:"万元",labelWidth:'120px',type:'number',required:false,span:8},
           {label:'承诺达产目标值',key:'targetValue',append:"万元",type:'number',required:false,span:8},
           {label:'承诺年税收',key:'annualTax',append:"万元",type:'number',required:false,span:8},
           {label:'生产工艺',key:'productionProcess',type:'textarea',span:24,required:false},
           // {label:'厂房地址',key:'remarks',type:'textarea',span:24,required:true},
       ]
       let applyForm = ref()
       let applyContext = ref<{id:number,context:TableActionClickCallbackContext}>()
       const showApplyFormDialog = ref(false);

       
        return {
            columns,formStructure,tableRef,apis,searchOptions,
            showApplyFormDialog,applyFormStructure,applyContext,applyForm,handleSubmitApply
            }
    }
})
